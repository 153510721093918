<template>
  <div class="createButtonWrapper">
    <el-button
      circle
      type="danger"
      icon="el-icon-plus"
      class="toggleCreateButton"
      @click.stop="toggleCreateButton"
    />
    <div
      :class="
        `hiddenButtonWrapper ${
          this.displayButton ? 'displayCreateScheduledButton' : ''
        }`
      "
    >
      <div class="scheduledTooltip">
        Add A Scheduled Lesson
      </div>
      <el-button
        circle
        type="primary"
        icon="el-icon-plus"
        @click="
          () => {
            $emit('openLessonScheduledForm');
          }
        "
        :class="`${this.displayButton ? 'displayCreateScheduledButton' : ''}`"
      />
    </div>
    <div
      :class="
        `hiddenButtonWrapper ${
          this.displayButton ? 'displayCreateLessonLogButton' : ''
        }`
      "
    >
      <div class="lessonLogTooltip">
        Add A Lesson Log
      </div>
      <router-link
        :to="{
          name: 'privateClassesCreateLog',
          params: { privateType, id: classId }
        }"
      >
        <el-button circle type="primary" icon="el-icon-plus" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classId: {
      type: [String, Number],
      default: ""
    },
    privateType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      displayButton: false
    };
  },
  methods: {
    toggleCreateButton() {
      this.displayButton = !this.displayButton;
    }
  }
};
</script>

<style lang="scss" scoped>
%hiddenCreateButton {
  z-index: -3;
  position: fixed;
  right: 50px;
  bottom: 50px;
  transition: bottom 1s;
  -moz-transition: bottom 1s;
  -webkit-transition: bottom 1s;
  -o-transition: bottom 1s;
}

%tooltip {
  width: 120px;
  position: fixed;
  display: none;
  background: #42a16a;
  color: #ffffff;
  padding: 4px;
  border-radius: 4px;
  right: 10px;
  font-size: 14px;
  z-index: -1;
  transition: background 0.5s;
  -moz-transition: background 0.5s;
  -webkit-transition: background 0.5s;
  -o-transition: background 0.5s;
}

.createButtonWrapper {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9;

  .hiddenButtonWrapper {
    @extend %hiddenCreateButton;
  }

  .lessonLogTooltip {
    @extend %tooltip;
    bottom: 155px;
  }

  .displayCreateLessonLogButton {
    bottom: 110px;
  }

  .displayCreateLessonLogButton:hover {
    .lessonLogTooltip {
      display: inline;
    }
  }

  .scheduledTooltip {
    @extend %tooltip;
    bottom: 205px;
  }

  .displayCreateScheduledButton {
    bottom: 160px;
  }

  .displayCreateScheduledButton:hover {
    .scheduledTooltip {
      display: inline;
    }
  }

  .toggleCreateButton {
    z-index: -2;
    font-size: 32px;
    width: 60px;
    height: 60px;
  }
}
</style>
