<template>
  <div>
    <el-row>
      <el-col>
        Student(s):
        <el-table :key="JSON.stringify(students)" :data="students">
          <el-table-column label="Name" width="260px">
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'ProfileForAdmin',
                  params: { id: scope.row.student.id }
                }"
              >
                {{
                  userMethods.displayName(
                    scope.row.student.first_name,
                    scope.row.student.last_name
                  )
                }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              {{ $t("userProfile.student.gradeLevel") }}
            </template>
            <template slot-scope="scope">
              {{
                getGrade(
                  scope.row.student.student_profile.high_school_graduation_year
                )
              }}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              {{ contactHeaderMap[sessionClass.contact_tool_preference] }}
            </template>
            <template slot-scope="scope">
              {{
                scope.row.student[
                  contactMap[sessionClass.contact_tool_preference]
                ]
              }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="isRoleAdmin() || isRoleParent()"
            label="Tuition"
          >
            <template slot-scope="scope">
              {{
                addCurrencySymbol(
                  sessionClass.is_online ? scope.row.online_fee : scope.row.fee,
                  sessionClass.is_online
                    ? scope.row.online_fee_currency
                    : scope.row.fee_currency
                )
              }}/hr
            </template>
          </el-table-column>
          <el-table-column
            v-if="isRoleAdmin()"
            :label="$t('enrollment.detail.contract')"
          >
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.enrolled_session_id"
                :to="{
                  name: 'EnrollmentDetail',
                  params: {
                    id: scope.row.enrolled_session_id
                  },
                  query: {
                    view: 'contract'
                  }
                }"
              >
                <i class="fa fa-eye"></i>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        Teacher(s):
        <el-table v-if="hasTeacher" :data="teachers">
          <el-table-column label="Name" width="260px">
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'ProfileForAdmin',
                  params: { id: scope.row.teacher_user_id }
                }"
              >
                {{
                  userMethods.displayName(
                    scope.row.teacher_user.first_name,
                    scope.row.teacher_user.last_name
                  )
                }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              {{ contactHeaderMap[sessionClass.contact_tool_preference] }}
            </template>
            <template slot-scope="scope">
              {{
                scope.row.teacher_user[
                  contactMap[sessionClass.contact_tool_preference]
                ]
              }}
            </template>
          </el-table-column>
          <el-table-column v-if="isRoleAdmin()" label="Salary">
            <template slot-scope="scope">
              {{
                addCurrencySymbol(
                  sessionClass.is_online
                    ? scope.row.online_salary
                    : scope.row.salary,
                  sessionClass.is_online
                    ? scope.row.online_salary_currency
                    : scope.row.salary_currency
                )
              }}/hr
            </template>
          </el-table-column>
        </el-table>
        <el-table v-else :data="sessionClass.class_teacher_invitations">
          <el-table-column type="index" width="50" />
          <el-table-column label="Name">
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'ProfileForAdmin',
                  params: { id: scope.row.teacher_user_id }
                }"
              >
                {{
                  userMethods.displayName(
                    scope.row.teacher_user.first_name,
                    scope.row.teacher_user.last_name
                  )
                }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="Invitation date" width="150">
            <template slot-scope="scope">
              {{ instant.formatGoogleTime(scope.row.created_at) }}
            </template>
          </el-table-column>

          <el-table-column label="Response" width="90">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="No Reply"
                placement="top"
              >
                <span
                  v-if="scope.row.replied_status === 0"
                  style="color:grey;"
                  label=" Not Replied"
                >
                  <i class="el-icon-question" />
                </span>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="Accepted"
                placement="top"
              >
                <span
                  v-if="scope.row.replied_status === 1"
                  style="color:forestgreen;"
                  label="Accepted"
                >
                  <i class="el-icon-success" />
                </span>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="Rejected"
                placement="top"
              >
                <span
                  v-if="scope.row.replied_status === 2"
                  style="color:indianred;"
                  label="Rejected"
                >
                  <i class="el-icon-error" />
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="" width="220">
            <template slot-scope="scope">
              <div v-if="editReplyRows[scope.row.teacher_user_id]">
                <el-button
                  :disabled="scope.row.replied_status === 1"
                  type="primary"
                  size="mini"
                  @click="
                    () => openTeacherAvailableDialog(scope.row.teacher_user_id)
                  "
                >
                  {{ $t("column.accepted") }}
                </el-button>

                <el-button
                  :disabled="scope.row.replied_status === 2"
                  type="danger"
                  size="mini"
                  @click="
                    () => {
                      replyInvitation(
                        scope.row.teacher_user_id,
                        scope.row.encrypted_id,
                        2
                      );
                    }
                  "
                >
                  {{ $t("column.rejected") }}
                </el-button>
                <span
                  v-if="editReplyRows[scope.row.teacher_user_id]"
                  @click="
                    () => {
                      closeEditReplyBtns(scope.row.teacher_user_id);
                    }
                  "
                >
                  <i class="el-icon-circle-close action-icon" />
                </span>
              </div>
              <div v-else>
                <el-tooltip
                  :content="
                    isRoleAdmin()
                      ? `幫老師接受或拒絕`
                      : $t('column.editResponse')
                  "
                >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="
                      () => {
                        openEditReplyBtns(scope.row);
                      }
                    "
                  >
                    <i class="fa fa-edit"></i>
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Availability" width="450">
            <template slot-scope="scope">
              {{ scope.row.replied_note }}
            </template>
          </el-table-column>

          <el-table-column
            v-if="isRoleAdmin()"
            label="Set Meet and Greet"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <span
                class="action-icon"
                @click="
                  () =>
                    changeTeacherNeedGreetMeeting(
                      scope.row.id,
                      scope.row.need_greet_meeting
                    )
                "
              >
                <span key="check" v-if="scope.row.need_greet_meeting">
                  <i class="far fa-check-square" />
                </span>
                <span v-else key="uncheck">
                  <i class="far fa-square" />
                </span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isRoleAdmin()"
            label="Actions"
            align="center"
            min-width="180"
          >
            <template slot-scope="scope">
              <GreetMeetingStatus
                v-if="scope.row.need_greet_meeting"
                :teacherGreetMeetings="scope.row.teacherGreetMeetings"
                :students="students"
                :sessionClass="sessionClass"
                @initialCustomLesson="$emit('initialCustomLesson')"
              />
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row v-if="isRoleAdmin() || isRoleTeacher()">
      <el-col :md="6">
        Course description:
      </el-col>
      <el-col :md="18">
        <div
          v-if="sessionClass.request_content"
          v-html="sessionClass.request_content.replace(/\n/g, '<br/>')"
        ></div>
      </el-col>
    </el-row>
    <el-row v-if="isRoleAdmin() || isRoleTeacher()">
      <el-col :md="6">
        Student's availability:
      </el-col>
      <el-col :md="18">
        <div
          v-if="sessionClass.request_time_info"
          v-html="sessionClass.request_time_info.replace(/\n/g, '<br/>')"
        ></div>
      </el-col>
    </el-row>
    <el-row v-if="isRoleAdmin() || isRoleTeacher()">
      <el-col :md="6">
        上課頻率：
      </el-col>
      <el-col :md="18">
        <div
          v-if="
            sessionClass.session_class_timeslots &&
              sessionClass.session_class_timeslots.length > 0
          "
        >
          <ul style="list-style: none;padding: 0;">
            <li
              v-for="(item, index) in sessionClass.session_class_timeslots"
              :key="index"
            >
              <b
                style="text-align:right;display: inline-block;width:1.5rem;"
                class="text-success"
              >
                {{ item.times }}
              </b>
              次

              <b class="text-success" v-if="item.per === 'M'">
                / 月
              </b>
              <b class="text-success" v-if="item.per === 'W'">
                / 週
              </b>
              -
              <span v-if="item.from">
                {{ item.from }}
              </span>
              <span v-if="item.from && item.to">
                ~
                {{ item.to }}
              </span>
            </li>
          </ul>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="6">
        Start Date:
      </el-col>
      <el-col :md="18">
        {{ sessionClass.started_at }}
      </el-col>
    </el-row>
    <el-row v-if="isRoleAdmin() || isRoleTeacher()">
      <el-col :md="6">
        <el-tooltip
          class="item"
          content="Only admin and the teacher can see this private notes."
          placement="top"
          popper-class="tooltipColor"
        >
          <i class="el-icon-lock" />
        </el-tooltip>
        Private notes (teacher):
      </el-col>
      <el-col :md="18">
        <div
          v-if="sessionClass.teacher_note"
          v-html="sessionClass.teacher_note.replace(/\n/g, '<br/>')"
        ></div>
      </el-col>
    </el-row>
    <el-row v-if="isRoleAdmin()">
      <el-col :md="6">
        <el-tooltip
          class="item"
          content="Only admin can see this private notes."
          placement="top"
          popper-class="tooltipColor"
        >
          <i class="el-icon-lock" />
        </el-tooltip>
        Private notes (admin):
      </el-col>
      <el-col :md="18">
        <div
          v-if="sessionClass.admin_note"
          v-html="sessionClass.admin_note.replace(/\n/g, '<br/>')"
        ></div>
      </el-col>
    </el-row>
    <TeacherAvailableDialog
      :visible="teacherAvailableDialogVisible"
      :classId="sessionClass.id"
      @onClose="teacherAvailableDialogVisible = false"
      @onSubmit="
        repliedNote =>
          replyInvitation(
            editTeacherId,
            editReplyRows[editTeacherId].encrypted_id,
            1,
            repliedNote
          )
      "
    />
  </div>
</template>

<script>
import moment from "moment";
import { instant, user } from "@ivy-way/material";
import arrangeApi from "@/apis/arrange";
import privateApi from "@/apis/private";
import roleMixin from "@/mixins/role";
import moneyMixin from "@/mixins/money";
import { EnumContactTools, ContactColumnKeyMap } from "@/enums";
import TeacherAvailableDialog from "@/views/private/TeacherAvailableDialog";
import GreetMeetingStatus from "@/views/private/GreetMeeting/GreetMeetingStatus";
import profileTest from "@/mixins/profileTest";

export default {
  props: {
    students: {
      type: Array,
      default: () => []
    },
    sessionClass: {
      type: Object,
      default: () => ({
        course_session: {},
        session_class_teachers: []
      })
    }
  },
  components: {
    TeacherAvailableDialog,
    GreetMeetingStatus
  },
  data() {
    return {
      teacherAvailableDialogVisible: false,
      greetMeetingDialogVisible: false,
      currentGreetMeeting: null,
      editTeacherId: null,
      editReplyRows: {}
    };
  },
  computed: {
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    },
    contactMap() {
      return ContactColumnKeyMap;
    },
    contactHeaderMap() {
      return EnumContactTools;
    },
    hasTeacher() {
      return this.teachers.length > 0 && !this.sessionClass.look_for_teacher;
    },
    teachers() {
      return this.sessionClass?.session_class_teachers || [];
    }
  },
  mixins: [profileTest, roleMixin, moneyMixin],
  methods: {
    openCreateGreetMeetingDialog() {
      this.currentGreetMeeting = null;
      this.greetMeetingDialogVisible = true;
    },
    openEditGreetMeetingDecidedTimeDialog(teacherGreetMeetings) {
      this.currentGreetMeeting = this.getNextGreetMeeting(teacherGreetMeetings);
      this.greetMeetingDialogVisible = true;
    },
    openEditGreetMeetingNotYetDecidedTimeDialog(teacherGreetMeetings) {
      this.currentGreetMeeting = this.getNotYetDecidedTimeGreetMeetings(
        teacherGreetMeetings
      )[0];
      this.greetMeetingDialogVisible = true;
    },
    closeGreetMeetingDialog() {
      this.currentGreetMeeting = null;
      this.greetMeetingDialogVisible = false;
    },
    openEditReplyBtns(teacher) {
      this.editReplyRows = {
        ...this.editReplyRows,
        [teacher.teacher_user_id]: teacher
      };
    },
    closeEditReplyBtns(teacherId) {
      this.editReplyRows = { ...this.editReplyRows, [teacherId]: false };
    },
    openTeacherAvailableDialog(teacherId) {
      this.teacherAvailableDialogVisible = true;
      this.editTeacherId = teacherId;
    },
    getAfterTodayGreetMeetings(teacherGreetMeetings) {
      const today = moment();
      const afterTodayGreetMeetings = teacherGreetMeetings.filter(
        ({ started_at }) => today.isBefore(moment(started_at))
      );
      return afterTodayGreetMeetings;
    },
    getNotYetDecidedTimeGreetMeetings(teacherGreetMeetings) {
      return teacherGreetMeetings.filter(
        ({ started_at }) => started_at === null
      );
    },
    greetMeetingIsAllAlreadyFinished(teacherGreetMeetings) {
      return (
        this.getAfterTodayGreetMeetings(teacherGreetMeetings).length === 0 &&
        this.getNotYetDecidedTimeGreetMeetings(teacherGreetMeetings).length ===
          0
      );
    },
    getLastGreetMeetingTime(teacherGreetMeetings) {
      let closestGreetMeeting = teacherGreetMeetings[0];
      teacherGreetMeetings.forEach(afterTodayGreetMeeting => {
        if (
          moment(closestGreetMeeting.started_at).isBefore(
            moment(afterTodayGreetMeeting.started_at)
          )
        ) {
          closestGreetMeeting = afterTodayGreetMeeting;
        }
      });
      return closestGreetMeeting;
    },
    getNextGreetMeeting(teacherGreetMeetings) {
      const afterTodayGreetMeetings = this.getAfterTodayGreetMeetings(
        teacherGreetMeetings
      );
      return this.getLastGreetMeetingTime(afterTodayGreetMeetings);
    },
    getNextGreetMeetingTime(teacherGreetMeetings) {
      const closestGreetMeeting = this.getNextGreetMeeting(
        teacherGreetMeetings
      );
      return `${closestGreetMeeting.started_at} (${closestGreetMeeting.timezone})`;
    },
    hasGreetMeetingTimeNotYetDecidedTime(teacherGreetMeetings) {
      return (
        this.getNotYetDecidedTimeGreetMeetings(teacherGreetMeetings).length > 0
      );
    },
    async changeTeacherNeedGreetMeeting(
      teacherInvitationId,
      originalNeedGreetMeeting
    ) {
      const [NEED, DO_NOT_NEED] = [1, 0];
      if (originalNeedGreetMeeting === NEED) {
        await privateApi.setNeedToGreetMeeting(
          teacherInvitationId,
          DO_NOT_NEED
        );
      } else {
        await privateApi.setNeedToGreetMeeting(teacherInvitationId, NEED);
      }
      this.$emit("initialCustomLesson");
      this.$router.go(0);
    },
    async replyInvitation(
      teacherId,
      teacherInvitationId,
      replyStatus,
      repliedNote
    ) {
      this.closeEditReplyBtns(teacherId);
      let param = {
        class_teacher_invitation_id: teacherInvitationId,
        replied_status: replyStatus
      };

      if (replyStatus === 1) {
        param.replied_note = repliedNote;
      }

      await arrangeApi.replyTeacherInvitation(param);
      this.teacherAvailableDialogVisible = false;
      this.$emit("initialCustomLesson");
    }
  }
};
</script>
