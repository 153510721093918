<template>
  <div>
    <el-dialog
      class="dialog"
      :visible.sync="visible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <template slot="title">
        <h2>
          Meet-and-Greet Information
          <template v-if="isRoleAdmin() && isViewMode">
            <span class="action-icon" @click="openEditMode">
              <i class="fas fa-edit" />
            </span>
            <DeleteIconButton :onDelete="deleteGreetMeeting()" />
          </template>
        </h2>
      </template>

      <GreetMeetingView
        v-if="isViewMode"
        :teachers="attachNewTeachers(teacherNeedGreetMeeting)"
        :theyWillBeMeeting="theyWillBeMeeting"
        :privateLessonAddress="address"
      />
      <GreetMeetingForm
        v-else
        :teachers="attachNewTeachers(teacherNeedGreetMeeting)"
        :theyWillBeMeeting="theyWillBeMeeting"
        :privateLessonAddress="address"
        @setTheyWillBeMeeting="setTheyWillBeMeeting"
      />
      <div style="height: 300px;overflow: auto;">
        <MailPreviewer
          v-if="isCreateMode || isEditMode"
          @setIntroNewTeacherMailContent="setNewEmail"
          :needUpdate="false"
          :id="sessionClass.id"
          :students="attachedNewStudents"
          :isOnline="sessionClass.is_online"
          :subject="sessionClass.subject"
          :teachers="attachNewTeachers(teacherNeedGreetMeeting)"
          :newTeachers="attachNewTeachers(greetMeetingTeachers)"
          :bioType="bioType"
          :hasMeeting="sessionClass.has_meeting"
          :theyWillBeMeetings="[theyWillBeMeeting]"
          :address="sessionClass.address"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel">
          {{ $t("button.cancel") }}
        </el-button>
        <el-button
          v-if="isRoleAdmin()"
          type="primary"
          @click="openCheckMailPeopleDialog"
        >
          {{ $t("button.save") }}
        </el-button>
      </span>
    </el-dialog>
    <CheckMailPeopleDialog
      :display="sendMailInformationDialogVisible"
      :mailToStudents="sendMailToPeople.mailToStudents"
      :mailToParents="sendMailToPeople.mailToParents"
      :mailToTeachers="sendMailToPeople.mailToTeachers"
      :students="attachedNewStudents"
      :teachers="attachNewTeachers(greetMeetingTeachers)"
      @onCancel="sendMailInformationDialogVisible = false"
      @onChangeMailToStudents="
        mailToStudents => setSendMailToPeople({ mailToStudents })
      "
      @onChangeMailToParents="
        mailToParents => setSendMailToPeople({ mailToParents })
      "
      @onChangeMailToTeachers="
        mailToTeachers => setSendMailToPeople({ mailToTeachers })
      "
      @onSubmit="saveGreetMeetingAndSendMail"
    />
  </div>
</template>

<script>
import roleMixin from "@/mixins/role";
import GreetMeeting from "@/class/GreetMeeting";
import GreetMeetingForm from "@/views/private/GreetMeeting/GreetMeetingForm";
import GreetMeetingView from "@/views/private/GreetMeeting/GreetMeetingView";
import MailPreviewer from "@/views/private/Form/MailPreviewer";
import CheckMailPeopleDialog from "@/views/private/Form/CheckMailPeopleDialog";
import DeleteIconButton from "@/components/button/DeleteIconButton";
import { EnumPrivateSessions } from "@/enums";

export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    greetMeeting: {
      default: null,
      type: Object
    },
    students: {
      default: () => [],
      type: Array
    },
    sessionClass: {
      default: null,
      type: Object
    }
  },
  mixins: [roleMixin],
  components: {
    GreetMeetingView,
    GreetMeetingForm,
    DeleteIconButton,
    MailPreviewer,
    CheckMailPeopleDialog
  },
  computed: {
    isViewMode() {
      return !this.isEditMode && !this.isCreateMode;
    },
    isCreateMode() {
      return this.greetMeeting === null;
    },
    attachedNewStudents() {
      return this.students.map(student => ({
        ...student,
        new_student: student.student
      }));
    },
    teacherNeedGreetMeeting() {
      return (this.sessionClass?.class_teacher_invitations || []).filter(
        ({ need_greet_meeting }) => need_greet_meeting
      );
    },
    greetMeetingTeachers() {
      return (
        this.sessionClass?.class_teacher_invitations || []
      ).filter(({ teacher_user_id }) =>
        this.theyWillBeMeeting.teachersId.includes(teacher_user_id)
      );
    },
    address() {
      return this.sessionClass?.address || "";
    },
    isCounseling() {
      return EnumPrivateSessions.counselingIds.includes(
        this.sessionClass.course_session.id
      );
    },
    bioType() {
      if (this.isCounseling) {
        return "counseling_bio";
      } else {
        return "tutoring_bio";
      }
    }
  },
  data() {
    return {
      isEditMode: false,
      sendMailInformationDialogVisible: false,
      sendMailToPeople: {
        mailToStudents: 1,
        mailToParents: 1,
        mailToTeachers: 1
      },
      theyWillBeMeeting: new GreetMeeting(),
      mailContent: ""
    };
  },
  watch: {
    greetMeeting() {
      if (this.greetMeeting) {
        const [date, time] = (this.greetMeeting.started_at || "").split(" ");
        this.theyWillBeMeeting = new GreetMeeting({
          teachersId: this.greetMeeting.greet_meeting_teachers.map(
            ({ teacher_user_id }) => teacher_user_id
          ),
          isDecidedMeetingTime: Boolean(this.greetMeeting.started_at),
          isSameOfClassAddress: Boolean(this.greetMeeting.is_same_address),
          date,
          time,
          timezone: this.greetMeeting.timezone,
          address: this.greetMeeting.address
        });
      } else {
        this.theyWillBeMeeting = new GreetMeeting();
      }
    }
  },
  methods: {
    setNewEmail(content) {
      this.mailContent = content;
    },
    formValidation() {
      if (this.theyWillBeMeeting.teachersId.length === 0) {
        this.$message.error("Participants 不得為空！");
        return false;
      }
      return true;
    },
    openCheckMailPeopleDialog() {
      const isValid = this.formValidation();
      if (!isValid) return;

      this.sendMailInformationDialogVisible = true;
    },
    openEditMode() {
      this.isEditMode = true;
    },
    setSendMailToPeople(value) {
      this.sendMailToPeople = {
        ...this.sendMailToPeople,
        ...value
      };
    },
    setTheyWillBeMeeting(value) {
      console.log(value);
      this.theyWillBeMeeting = new GreetMeeting({
        ...this.theyWillBeMeeting,
        ...value
      });
    },
    attachNewTeachers(teachers) {
      let newTeachers = JSON.parse(JSON.stringify(teachers));
      return (newTeachers || []).map(teacher => ({
        ...teacher,
        new_teacher: {
          ...teacher.teacher_user,
          tutoring_bio: teacher.teacher_user.teacher_profile.tutoring_bio,
          counseling_bio: teacher.teacher_user.teacher_profile.counseling_bio
        },
        new_teacher_user_id: teacher.teacher_user_id
      }));
    },
    onCancel() {
      this.isEditMode = false;
      this.sendMailInformationDialogVisible = false;
      this.theyWillBeMeeting = new GreetMeeting();
      this.$emit("onCancel");
    },
    deleteGreetMeeting() {
      return async () => {
        await this.$store.dispatch("privateLesson/deleteGreetMeeting", {
          greetMeetingId: this.greetMeeting.id
        });
        this.$emit("initialCustomLesson");
        this.onCancel();
      };
    },
    async sendGreetMeetingMail() {
      console.log(this.mailContent);
      await this.$store.dispatch("privateLesson/sendGreetMeetingMail", {
        classId: this.sessionClass.id,
        mailToPeople: this.sendMailToPeople,
        sendTeachersId: this.theyWillBeMeeting.teachersId,
        mailContent: this.mailContent
      });
    },
    async saveGreetMeetingAndSendMail() {
      console.log(this.mailContent);
      try {
        if (this.isEditMode) {
          await this.$store.dispatch("privateLesson/updateGreetMeeting", {
            greetMeetingId: this.greetMeeting.id,
            classAddress: this.address,
            theyWillBeMeeting: this.theyWillBeMeeting
          });
          this.$message.success(this.$t("message.create_success"));
        } else if (this.isCreateMode) {
          await this.$store.dispatch("privateLesson/createGreetMeetings", {
            classId: this.sessionClass.id,
            classAddress: this.address,
            theyWillBeMeetings: [this.theyWillBeMeeting]
          });
          this.$message.success(this.$t("message.create_success"));
        }
        console.log(this.mailContent);
        await this.sendGreetMeetingMail();
        this.onCancel();
        this.$emit("initialCustomLesson");
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style scoped>
::v-deep .el-dialog__body,
::v-deep .el-dialog__header {
  text-align: left;
}

::v-deep .el-form-item__label {
  line-height: 26px;
  text-align: left;
}
::v-deep .el-dialog__body .el-form-item__label {
  line-height: 40px;
  text-align: right;
}

::v-deep label {
  margin-bottom: 0px;
  line-height: 20px;
}

::v-deep .el-dialog {
  width: 1000px;
}

@media (max-width: 992px) {
  ::v-deep .el-dialog {
    width: 90%;
  }
}
</style>
