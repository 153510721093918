<template>
  <el-form label-position="right" label-width="150px">
    <el-form-item label="Participants">
      <b>
      {{
        helper.displayMultipleItems(
          participantTeachers.map(({ new_teacher }) =>
            userMethods.displayName(
              new_teacher.first_name,
              new_teacher.last_name
            )
          )
        )
      }}
      </b>
    </el-form-item>
    <el-form-item label="Meeting time">
      <b v-if="theyWillBeMeeting.isDecidedMeetingTime">
        {{
          `${instant.formatDateTime(
            `${theyWillBeMeeting.date} ${theyWillBeMeeting.time}`
          )} ${theyWillBeMeeting.timezone}`
        }}
      </b>
      <b v-else>待定</b>
    </el-form-item>
    <el-form-item label="Meeting location">
      <bdi v-if="theyWillBeMeeting.isSameOfClassAddress">
        {{ privateLessonAddress }}
      </bdi>
      <b v-else>
        {{ theyWillBeMeeting.address }}
      </b>
    </el-form-item>
  </el-form>
</template>

<script>
import { user, helper, instant } from "@ivy-way/material";

export default {
  props: {
    teachers: {
      type: Array,
      default: () => []
    },
    theyWillBeMeeting: {
      type: Object,
      default: () => ({})
    },
    privateLessonAddress: {
      type: String,
      default: ""
    }
  },
  computed: {
    helper() {
      return helper;
    },
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    },
    participantTeachers() {
      return this.teachers.filter(teacher =>
        this.theyWillBeMeeting.teachersId.includes(teacher.teacher_user_id)
      );
    }
  }
};
</script>
<style scoped>
::v-deep.el-form-item .el-form-item__label {
  line-height: 40px !important;
}
</style>
