<template>
  <div class="container">
    <el-dialog
      v-if="teacher"
      title="Add A Scheduled Lesson"
      :visible="scheduledVisible"
      width="80%"
      :before-close="closeLessonScheduledForm"
    >
      <LessonScheduledForm
        :classId="Number($route.params.id)"
        :teacherUserId="teacher.id"
        :salary="Number(sessionClass.salary)"
        :salaryCurrency="sessionClass.salary_currency"
        :studentClassTickets="students"
        @createSchedules="createSchedules"
        @closeLessonScheduledForm="closeLessonScheduledForm"
      />
    </el-dialog>
    <CreateLessonLogButton
      v-if="isRoleAdmin() || (isRoleTeacher() && isTeacherInClass)"
      :classId="$route.params.id"
      :privateType="privateType"
      @openLessonScheduledForm="openLessonScheduledForm"
    />
    <Breadcrumb>
      <template slot="lastPage">
        <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
        {{ sessionClass.title }}
      </template>
    </Breadcrumb>
    <el-divider />
    <h1>
      <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
      {{ sessionClass.title }}
      <template v-if="isRoleAdmin()">
        <router-link
          v-if="sessionClass.subject_type == 'Counseling'"
          :to="{
            name: 'privateClassesEdit',
            params: { privateType: 'counseling', class_id: $route.params.id }
          }"
        >
          <i class="fas fa-edit" />
        </router-link>
        <router-link
          v-else
          :to="{
            name: 'privateClassesEdit',
            params: { privateType, class_id: $route.params.id }
          }"
        >
          <i class="fas fa-edit" />
        </router-link>
      </template>
    </h1>
    <div>
      <hr class="separate-line" />
      <h2>Lesson Info</h2>
      <PrivateLessonInfo
        :students="students"
        :sessionClass="sessionClass"
        @initialCustomLesson="initialCustomLesson"
      />
      <!-- scores -->
      <div v-if="selectClass.length > 0 && selectClass[0].exams.length > 0">
        <hr class="separate-line" />
        <h2>Students Scores</h2>
        <div>
          <div v-for="(section, index) in selectClass" :key="index">
            <!-- <StudentTestScoreTable
              :section="section"
              @getSessions="getSessions"
              :disableEdit="!(isRoleAdmin() || (isRoleTeacher() && isTeacherInClass))"
              :displayStudentUserIds="
                isRoleAdmin() || (isRoleTeacher() && isTeacherInClass)
                  ? null
                  : $store.getters['user/getProfile'].id
              "
            /> -->
            <StudentTestScoreTable
              :section="section"
              @getSessions="getSessions"
              :disableEdit="!(isRoleAdmin() || (isRoleTeacher() && isTeacherInClass))"
              :displayStudentUserIds="displayStudentUserIds()"
            />
          </div>
        </div>
      </div>
      <hr class="separate-line" />
      <div class="lessonLogs" v-if="teacher" style="margin-bottom: 48px">
        <el-row>
          <el-col :md="8">
            <h2>
              Lesson Logs - {{ filterPaid ? "Unpaid" : "All" }}
              <i
                v-if="isRoleAdmin() || (isRoleTeacher() && isTeacherInClass)"
                class="el-icon-plus action-icon"
                @click="
                  $router.push(
                    `/private/private-lessons/${$route.params.id}/createLog`
                  )
                "
              />
            </h2>
          </el-col>
          <el-col :md="12">
            <el-button
              type="primary"
              v-if="isRoleAdmin() || !isRoleStudent()"
              @click="
                () => {
                  filterPaid = !filterPaid;
                }
              "
            >
              Show {{ filterPaid ? "All" : "Unpaid" }}
            </el-button>
            <el-button
              type="primary"
              v-if="
                (isRoleAdmin() || (isRoleTeacher() && isTeacherInClass)) &&
                  existOldStudentsOrTeachers &&
                  !i_am_old_teacher
              "
              @click="
                () => {
                  filterActive = !filterActive;
                }
              "
            >
              Show
              {{
                filterActive
                  ? "Active Teacher/Students Only"
                  : "With Inactive Teachers/Students"
              }}
            </el-button>
          </el-col>
        </el-row>
        <el-tabs v-model="currenctLessonTab" @tab-click="() => {}">
          <template
            v-if="(isRoleAdmin() || (isRoleTeacher() && isTeacherInClass)) && !this.i_am_old_teacher"
          >
            <el-tab-pane
              :name="String(teacher.teacher_user_id)"
              :key="teacher.teacher_user_id"
              v-for="teacher in teachers"
            >
              <template slot="label">
                <i class="fas fa-user-graduate" />
                {{ teacher.teacher_user.name }}
              </template>
              <TeachingLogs
                :classId="Number($route.params.id)"
                :teachingLogs="teachingLogs[teacher.teacher_user_id]"
                :teacherUserId="teacher.teacher_user_id"
                :teacherUserName="teacher.teacher_user.name"
                :filterPaid="filterPaid"
                @reloadCustomLesson="initialCustomLesson"
              />
            </el-tab-pane>
          </template>
          <template
            v-if="
              (filterActive == false && (isRoleAdmin() || (isRoleTeacher() && isTeacherInClass))) ||
                this.i_am_old_teacher
            "
          >
            <el-tab-pane
              :key="old_teacher.id"
              v-for="old_teacher in old_teachers"
              :name="String(old_teacher.id)"
            >
              <template slot="label">
                <i class="fas fa-user-graduate old-tab" />
                <span class="old-tab">{{ old_teacher.name }}</span>
              </template>
              <el-alert
                title="老師目前已沒有教這個班級"
                type="warning"
                :closable="false"
                show-icon
              >
              </el-alert>
              <TeachingLogs
                :classId="Number($route.params.id)"
                :teachingLogs="teachingLogs[old_teacher.id]"
                :teacherUserId="old_teacher.id"
                :teacherUserName="old_teacher.name"
                :filterPaid="filterPaid"
                :showSalary="old_teacher.id == my_id"
                @reloadCustomLesson="initialCustomLesson"
              />
            </el-tab-pane>
          </template>
          <template>
            <el-tab-pane
              :key="student.id"
              v-for="{ student } in students"
              :label="student.name"
              :name="String(student.id)"
            >
              <template slot="label">
                <i class="far fa-user" />
                {{ student.name }}
              </template>
              <LessonLogs
                :classId="Number($route.params.id)"
                :lessonLogs="lessonLogs[student.id]"
                :enrollSessionId="getEnrollSessionId(student.id)"
                :filterPaid="filterPaid"
                @reloadCustomLesson="initialCustomLesson"
              />
            </el-tab-pane>
          </template>
          <template v-if="filterActive == false">
            <el-tab-pane
              :key="student.id"
              v-for="{ student } in old_students"
              :label="student.name"
              :name="String(student.id)"
            >
              <template slot="label">
                <i class="far fa-user old-tab" />
                <span class="old-tab">{{ student.name }}</span>
              </template>
              <el-alert
                title="學生目前已不在此班上"
                type="warning"
                :closable="false"
                show-icon
              >
              </el-alert>
              <LessonLogs
                :classId="Number($route.params.id)"
                :lessonLogs="lessonLogs[student.id]"
                :enrollSessionId="getOldEnrollSessionId(student.id)"
                :filterPaid="filterPaid"
                @reloadCustomLesson="initialCustomLesson"
              />
            </el-tab-pane>
          </template>
        </el-tabs>
      </div>
      <div class="reports" v-if="teacher">
        <h2>
          Progress Reports
          <router-link
            v-if="isRoleAdmin() || (isRoleTeacher() && isTeacherInClass)"
            :to="
              `/private/private-lessons/${$route.params.id}/progress-report/create`
            "
          >
            <i class="el-icon-plus action-icon" />
          </router-link>
        </h2>
        <hr class="separate-line" />
        <el-alert
          v-if="
            (isRoleAdmin() || isRoleTeacher()) &&
              progressReportsCompletedCount < progressReportsNeededCount
          "
          style="margin: 20px 0px"
          show-icon
          type="warning"
        >
          <template slot="title">
            <span
              v-html="
                $t('message.teacherNeedToWriteReports', {
                  howManyReportNeedToWrite: progressReportsNeededCount,
                  writtenReports: progressReportsCompletedCount
                })
              "
            />
          </template>
        </el-alert>

        <template
          v-if="(isRoleAdmin() || (isRoleTeacher() && isTeacherInClass)) && !this.i_am_old_teacher"
        >
          <div v-for="teacher in teachers" :key="teacher.teacher_user_id">
            <div>{{ teachers.teacher_user_id }}</div>
            <ClassReports
              :className="sessionClass.title"
              :classId="$route.params.id"
              :reports="classReports[teacher.teacher_user_id]"
              @fetchReport="fetchReport"
            />
          </div>
        </template>
        <template
          v-if="
            (filterActive == false && (isRoleAdmin() || (isRoleTeacher() && isTeacherInClass))) ||
              this.i_am_old_teacher
          "
        >
          <div :key="old_teacher.id" v-for="old_teacher in old_teachers">
            <template slot="label">
              <i class="fas fa-user-graduate old-tab" />
              <span class="old-tab">{{ old_teacher.name }}</span>
            </template>
            <ClassReports
              :classId="$route.params.id"
              :reports="classReports[old_teacher.id]"
              @fetchReport="fetchReport"
            />
          </div>
        </template>
        <el-tabs v-model="currenctReportTab" @tab-click="() => {}" v-else>
          <template>
            <el-tab-pane
              :key="student.id"
              v-for="{ student } in students"
              :label="student.name"
              :name="String(student.id)"
            >
              <template slot="label">
                <i class="far fa-user" />
                {{ student.name }}
              </template>
              <StudentReports
                :classId="$route.params.id"
                :reports="studentReports[student.id]"
                @fetchReport="fetchReport"
              />
            </el-tab-pane>
          </template>
          <template v-if="filterActive == false">
            <el-tab-pane
              :key="student.id"
              v-for="{ student } in old_students"
              :label="student.name"
              :name="String(student.id)"
            >
              <template slot="label">
                <i class="far fa-user old-tab" />
                <span class="old-tab">{{ student.name }}</span>
              </template>
              <StudentReports
                :className="sessionClass.title"
                :classId="$route.params.id"
                :reports="studentReports[student.id]"
                @fetchReport="fetchReport"
              />
            </el-tab-pane>
          </template>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import courseApi from "@/apis/course";
import Breadcrumb from "@/components/Breadcrumb";
import CreateLessonLogButton from "./CreateLessonLogButton";
import LessonScheduledForm from "@/views/lessonLog/LessonScheduledForm";
import IsOnlineIcon from "@/components/icon/IsOnline";
import PrivateLessonInfo from "@/views/private/PrivateLessonInfo";
import TeachingLogs from "@/views/lessonLog/TeachingLogs";
import LessonLogs from "@/views/lessonLog/LessonLogs";
import StudentReports from "@/views/report/StudentReports";
import ClassReports from "@/views/report/ClassReports";
import lessonLogApi from "@/apis/lessonLog";
import privateApi from "@/apis/private";
import reportApi from "@/apis/report";
import moneyMixin from "@/mixins/money";
import roleMixin from "@/mixins/role";
import StudentTestScoreTable from "@/components/sessionClass/StudentTestScoreTable";

export default {
  metaInfo() {
    return {
      title: `${this.isOnlineText} ${this.sessionClass.title ||
        ""} - Ivy-Way Academy`
    };
  },
  watch: {
    students() {
      if (this.$store.getters["user/isRole"]("parent")) {
        this.currenctLessonTab = String(this.students[0].student.id);
      }
    }
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    isOnlineText() {
      switch (this.sessionClass.is_online) {
        case 1:
          return "Online";
        case 0:
          return "In-person";
        default:
          return "";
      }
    },
    isTeacherInClass() {
      let isTeacherInClass = false;
      if (this.sessionClass && this.sessionClass.session_class_teachers) {
        console.log(this.sessionClass.session_class_teachers);
        this.sessionClass.session_class_teachers.forEach(teacher => {
          if (teacher.teacher_user_id === this.getProfile.id) {
            isTeacherInClass = true;
          }
        });
      }
      return isTeacherInClass;
    },
    privateType() {
      if (this.$route.name === "privateClassesDetail") {
        return "private-lessons";
      } else if (this.$route.name === "counselingClassesDetail") {
        return "counseling";
      }
      return "";
    },
    existOldStudentsOrTeachers() {
      return this.old_students.length > 0 || this.old_teachers.length > 0;
    }
  },
  data() {
    return {
      filterPaid: false,
      filterActive: true,
      scheduledVisible: false,
      subject: "",
      fee: 0,
      feeCurrency: "TWD",
      students: [],
      old_students: [],
      teachers: [],
      teacher: {},
      old_teachers: [],
      lessonLogs: {},
      teachingLogs: {},
      classReports: [],
      studentReports: [],
      progressReportsCompletedCount: 0,
      progressReportsNeededCount: 0,
      sessionClass: {
        course_session: {}
      },
      currenctLessonTab: "teacher",
      currenctReportTab: "teacher",
      i_am_old_teacher: 0,
      my_id: 0,
      selectClass: []
    };
  },
  mixins: [moneyMixin, roleMixin],
  components: {
    Breadcrumb,
    IsOnlineIcon,
    PrivateLessonInfo,
    CreateLessonLogButton,
    LessonScheduledForm,
    TeachingLogs,
    LessonLogs,
    ClassReports,
    StudentReports,
    StudentTestScoreTable
  },
  async created() {
    await this.initialCustomLesson();
    await this.fetchReport();
  },
  mounted() {
    this.getSessions();
  },
  methods: {
    displayStudentUserIds() {
      console.log(this.isRoleStudent());
      if (this.isRoleAdmin() || (this.isRoleTeacher() && this.isTeacherInClass)) {
        return null;
      } else {
        if (this.isRoleStudent()) {
          console.log(this.$store.getters["user/getProfile"].id);
          return [this.$store.getters["user/getProfile"].id];
        } else if (this.isRoleParent()) {
          return this.students.map(({ student_user_id }) => student_user_id);
        } else {
          return [];
        }
      }
    },
    async getSessions() {
      const { session_class } = await courseApi.fetchSessionClass(
        this.$route.params.id
      );
      const courseSessionRes = await courseApi.fetchSession(
        session_class.course_session_id
      );
      this.courseSession = courseSessionRes.course_session;
      const courseRes = await courseApi.fetchCourse(
        this.courseSession.course_id
      );
      this.course = courseRes.course;

      this.selectClass = [session_class];
    },
    getEnrollSessionId(studentId) {
      return this.students.find(
        student => student.student_user_id === studentId
      ).enrolled_session_id;
    },
    getOldEnrollSessionId(studentId) {
      return this.old_students.find(
        student => student.student_user_id === studentId
      ).enrolled_session_id;
    },
    async fetchReport() {
      const {
        class_progress_reports_group_by_user: classReports,
        student_progress_reports_group_by_user: studentReports,
        progress_reports_completed_count,
        progress_reports_needed_count
      } = await reportApi.fetchReports(this.$route.params.id);
      if (this.teacher) {
        this.classReports = classReports;
        this.studentReports = studentReports;
        this.progressReportsCompletedCount = progress_reports_completed_count;
        this.progressReportsNeededCount = progress_reports_needed_count;
      }
    },
    async initialCustomLesson() {
      const filterCanNotSeeOtherTeacher = teachers => {
        if (this.isRoleAdmin()) return teachers;
        const meetingTeacher = teachers.find(
          ({ teacher_user_id }) =>
            teacher_user_id === this.$store.getters["user/getProfile"].id
        );
        if (meetingTeacher.can_see_others) return teachers;
        return [meetingTeacher];
      };
      const attachGreetMeetingInformation = async teacherInvitations => {
        const {
          greet_meetings: greetMeetings
        } = await privateApi.getGreetMeetings({
          session_class_id: this.$route.params.id
        });
        return teacherInvitations.map(teacherInvitation => {
          const teacherGreetMeetings = greetMeetings.filter(
            ({ greet_meeting_teachers }) =>
              greet_meeting_teachers.some(
                ({ teacher_user_id }) =>
                  teacher_user_id === teacherInvitation.teacher_user_id
              )
          );
          return {
            ...teacherInvitation,
            teacherGreetMeetings
          };
        });
      };

      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.id
      });
      this.sessionClass = {
        ...customLesson.session_class,
        class_teacher_invitations: await attachGreetMeetingInformation(
          customLesson.session_class.class_teacher_invitations
            ? customLesson.session_class.class_teacher_invitations
            : []
        )
      };
      const lessonDetail = customLesson.session_class;
      this.fee = lessonDetail.fee;
      this.feeCurrency = lessonDetail.feeCurrency;

      this.students = customLesson.student_class_tickets;
      this.old_students = customLesson.old_student_class_tickets;
      this.old_teachers = customLesson.old_teachers;

      if (this.isRoleAdmin() || (this.isRoleTeacher() && this.isTeacherInClass)) {
        this.teachers = filterCanNotSeeOtherTeacher(
          this.sessionClass.session_class_teachers
        );
        this.currenctLessonTab = String(this.teachers[0].teacher_user_id);
        this.currenctReportTab = String(this.teachers[0].teacher_user_id);
      } else {
        this.currenctLessonTab = String(this.students[0].student.id);
        this.currenctReportTab = String(this.students[0].student.id);
      }

      this.teacher = customLesson.teacher_user;

      if (this.teacher) {
        this.teachingLogs = customLesson.teaching_logs_group_by_user;
        this.lessonLogs = customLesson.lesson_logs_group_by_user;
      }
      this.i_am_old_teacher = customLesson.i_am_old_teacher;
      this.my_id = customLesson.my_id;
      if (this.i_am_old_teacher) {
        this.currenctLessonTab = this.my_id;
      }
    },
    openLessonScheduledForm() {
      this.scheduledVisible = true;
    },
    closeLessonScheduledForm() {
      this.scheduledVisible = false;
    },
    createSchedules(schedules) {
      const pomiseSchedules = schedules.map(
        async schedule => await lessonLogApi.createLog(schedule)
      );

      Promise.all(pomiseSchedules).then(async values => {
        await this.initialCustomLesson();
        this.closeLessonScheduledForm();
      });
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 1400px;
}

::v-deep .el-row {
  margin: 8px 0px;
}

.old-tab {
  opacity: 0.4;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
