<template>
  <div v-if="isRoleAdmin() || isRoleTeacher()">
    <el-tooltip effect="dark" content="Add a new meeting" placement="top">
      <el-button
        type="primary"
        size="mini"
        @click="openCreateGreetMeetingDialog"
        class="action-text"
      >
        <i class="fa fa-plus"></i>
      </el-button>
    </el-tooltip>
    <el-tooltip
      v-if="teacherGreetMeetings.length > 0"
      effect="dark"
      content="View All meetings"
      placement="top"
    >
      <el-button
        type="warning"
        size="mini"
        @click="showMeetings = true"
        class="action-text"
      >
        <i class="fa fa-eye"></i>
      </el-button>
    </el-tooltip>
    <el-dialog class="dialog" :visible.sync="showMeetings" width="60%">
      <template slot="title">
        <h2>
          All Meetings
        </h2>
      </template>
      <div class="mb-4">
        <el-table
          height="300"
          :data="orderByDate(teacherGreetMeetings)"
          style="width: 100%"
        >
          <el-table-column prop="date" label="日期" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.started_at">
                {{ instant.formatGoogleTime(scope.row.started_at) }}
              </span>
              <span v-else>
                -
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Status" min-width="100">
            <template slot-scope="scope">
              <span v-if="greetMeetingIsAllAlreadyFinished([scope.row])">
                {{
                  `見面會於 ${instant.formatGoogleTime(
                    getLastGreetMeetingTime([scope.row]).started_at
                  )} 結束`
                }}
              </span>
              <span v-else class="action-text">
                <span
                  v-if="hasGreetMeetingTimeNotYetDecidedTime([scope.row])"
                  @click="
                    () =>
                      openEditGreetMeetingNotYetDecidedTimeDialog([scope.row])
                  "
                >
                  <i class="fa fa-edit"></i>
                  已有見面會，但尚未決定時間
                </span>
                <span
                  v-else
                  @click="
                    () => openEditGreetMeetingDecidedTimeDialog([scope.row])
                  "
                >
                  <i class="fa fa-edit"></i>
                  {{ getNextGreetMeetingTime([scope.row]) }}
                </span>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="Address">
            <template slot-scope="scope">
              {{ scope.row.address }}
            </template>
          </el-table-column>
          <el-table-column label="Actions">
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="Edit Meeting" placement="top">
                <el-button
                  type="primary"
                  size="mini"
                  @click="() => openEditMeeting([scope.row])"
                >
                  <i class="fa fa-edit"></i>
                </el-button>
              </el-tooltip>
              <!-- <el-tooltip
                effect="dark"
                content="Remove Meeting"
                placement="top"
              >
                <el-button type="danger" size="mini">
                  <i class="fa fa-trash-alt"></i>
                </el-button>
              </el-tooltip> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <HandleGreetMeetingDialog
      :visible="greetMeetingDialogVisible"
      :students="students"
      :sessionClass="sessionClass"
      :greetMeeting="currentGreetMeeting"
      @onCancel="closeGreetMeetingDialog"
      @initialCustomLesson="$emit('initialCustomLesson')"
    />
  </div>
</template>

<script>
import moment from "moment";
import { instant } from "@ivy-way/material";
import roleMixin from "@/mixins/role";
import HandleGreetMeetingDialog from "@/views/private/GreetMeeting/HandleGreetMeetingDialog";
import _ from "lodash";

export default {
  props: {
    sessionClass: {
      type: Object,
      default: null
    },
    students: {
      type: Array,
      default: () => []
    },
    teacherGreetMeetings: {
      type: Array,
      default: () => []
    }
  },
  components: {
    HandleGreetMeetingDialog
  },
  data() {
    return {
      greetMeetingDialogVisible: false,
      currentGreetMeeting: null,
      showMeetings: false
    };
  },
  computed: {
    instant() {
      return instant;
    }
  },
  mixins: [roleMixin],
  methods: {
    // deleteGreetMeeting(id) {
    //   return async () => {
    //     await this.$store.dispatch("privateLesson/deleteGreetMeeting", {
    //       greetMeetingId: id
    //     });
    //   };
    // },
    orderByDate(arr) {
      return _.orderBy(arr, ["started_at", "asc"]);
    },
    openCreateGreetMeetingDialog() {
      this.currentGreetMeeting = null;
      this.greetMeetingDialogVisible = true;
    },
    openEditGreetMeetingDecidedTimeDialog(teacherGreetMeetings) {
      this.currentGreetMeeting = this.getNextGreetMeeting(teacherGreetMeetings);
      this.greetMeetingDialogVisible = true;
    },
    openEditGreetMeetingNotYetDecidedTimeDialog(teacherGreetMeetings) {
      this.currentGreetMeeting = this.getNotYetDecidedTimeGreetMeetings(
        teacherGreetMeetings
      )[0];
      this.greetMeetingDialogVisible = true;
    },
    openEditMeeting(teacherGreetMeetings) {
      this.currentGreetMeeting = teacherGreetMeetings[0];
      this.greetMeetingDialogVisible = true;
    },
    closeGreetMeetingDialog() {
      this.currentGreetMeeting = null;
      this.greetMeetingDialogVisible = false;
    },
    getAfterTodayGreetMeetings(teacherGreetMeetings) {
      const today = moment();
      const afterTodayGreetMeetings = teacherGreetMeetings.filter(
        ({ started_at }) => today.isBefore(moment(started_at))
      );
      return afterTodayGreetMeetings;
    },
    getNotYetDecidedTimeGreetMeetings(teacherGreetMeetings) {
      return teacherGreetMeetings.filter(
        ({ started_at }) => started_at === null
      );
    },
    greetMeetingIsAllAlreadyFinished(teacherGreetMeetings) {
      return (
        this.getAfterTodayGreetMeetings(teacherGreetMeetings).length === 0 &&
        this.getNotYetDecidedTimeGreetMeetings(teacherGreetMeetings).length ===
          0
      );
    },
    getLastGreetMeetingTime(teacherGreetMeetings) {
      let closestGreetMeeting = teacherGreetMeetings[0];
      teacherGreetMeetings.forEach(afterTodayGreetMeeting => {
        if (
          moment(closestGreetMeeting.started_at).isBefore(
            moment(afterTodayGreetMeeting.started_at)
          )
        ) {
          closestGreetMeeting = afterTodayGreetMeeting;
        }
      });
      return closestGreetMeeting;
    },
    getNextGreetMeeting(teacherGreetMeetings) {
      const afterTodayGreetMeetings = this.getAfterTodayGreetMeetings(
        teacherGreetMeetings
      );
      return this.getLastGreetMeetingTime(afterTodayGreetMeetings);
    },
    getNextGreetMeetingTime(teacherGreetMeetings) {
      const closestGreetMeeting = this.getNextGreetMeeting(
        teacherGreetMeetings
      );
      return `${instant.formatDateTime(closestGreetMeeting.started_at)} (${
        closestGreetMeeting.timezone
      })`;
    },
    hasGreetMeetingTimeNotYetDecidedTime(teacherGreetMeetings) {
      return (
        this.getNotYetDecidedTimeGreetMeetings(teacherGreetMeetings).length > 0
      );
    }
  }
};
</script>
